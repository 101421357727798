<template>
  <!-- 실제 본문 시작 -->
  <div class="cont-body-wrap">
    <div class="inner-body-wrap st-full">
      <div class="layout-btn-fixed" style="padding-bottom: 1rem;">
        <div class="com-frm-box st-mt30">
            <div class="com-inner-box-st1">
              <h2 class="h2">본인확인</h2>
              <div class="row-box">
                <h3 class="h3">이름</h3>
                <div class="input-box st-wide">
                  <input type="text" title="이름" class="st-input" placeholder="성함을 기재해주세요."  v-model="userInfo.name">
                </div>
              </div>
              <div class="row-box">
                <h3 class="h3">생년월일</h3>
                <div class="input-box  st-birth">
                  <select class="st-input" v-model="userInfo.birthYY">
                    <option value="">년도</option>
                    <option v-for="yyyy in yyyyList" :key="yyyy">{{yyyy}}</option>
                  </select>
                  <select class="st-input" v-model="userInfo.birthMM">
                    <option value="">월</option>
                    <option v-for="mm in mmList" :key="mm">{{mm}}</option>
                  </select>
                  <select class="st-input" v-model="userInfo.birthDate">
                    <option value="">일</option>
                    <option v-for="dd in ddList" :key="dd">{{dd}}</option>
                  </select>
                </div>
                <div class="input-box st-birth-chk">
                  <input type="radio" class="ra-st1" name="birth-chk" id="birth1" v-model="userInfo.lunarBirth" value=false><label for="birth1">양력</label>
                  <input type="radio" class="ra-st1" name="birth-chk" id="birth2" v-model="userInfo.lunarBirth" value=true><label for="birth2">음력</label>
                  <span class="exp">주민등록상 생년월일을 입력해주세요.</span>
                </div>
              </div>
              <div class="row-box">
                <h3 class="h3">휴대폰 인증</h3>
                <div class="input-box st-hp">
                  <input type="text" class="st-input" placeholder="번호를 입력해주세요. (-제외)" v-model="userInfo.moblNo" @input="onInputMobile" maxlength="11">
                  <a href="javascript:;" class="st1" @click="onClickSendAuthNo">인증번호 발송</a>
                </div>
                <div class="input-box st-hp">
                  <input type="text" class="st-input" placeholder="인증번호 입력" v-model="userInfo.authNo"><a href="javascript:;" class="st2" @click="onClickCheckAuth">인증하기</a>
                </div>
              </div>
            </div>

            <div class="agree-box">
              <div class="tit">
                청탁금지법 대상 여부
                <a href="javascript:;" class="btn-more btnAgreeInfo01" @click="popupAgree1(true)">자세히보기</a>
              </div>
              <div class="agree-yn-box">
                <input type="radio" name="agree-yn" id="agree-yn1" :disabled="isSent" class="ra-st1 st-fnt" v-model="userInfo.antiGraftLawTarget" value=0><label for="agree-yn1">대상자</label>
                <input type="radio" name="agree-yn" id="agree-yn2" :disabled="isSent" class="ra-st1 st-fnt" v-model="userInfo.antiGraftLawTarget" value=1><label for="agree-yn2">비대상자</label>
              </div>
              <div class="sub-box bg">
                <ul>
                  <li>
                    <div class=""><input type="checkbox" id="agree2" :disabled="isSent" class="chk-st1" v-model="userInfo.personalInformationAgreement"><label for="agree2">개인정보 수집 이용에 대한 동의</label>
                    </div>
                    <a href="javascript:;" class="btn-more btnAgreeInfo02" @click="popupAgree2(true)">자세히보기</a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="com-inner-box-st1">
          <div class="com-btn-box" style="position: inherit; padding-top: 1rem;"><a href="javascript:;" class="btnFinishMsg" @click="onClickNext()">확인</a></div>
          <!-- 하단에 스크립트 있음 : btnFinishMsg -->
        </div>
      </div>
    </div>


    <!-- 신청 완료 메세지 -->
    <div class="dg-pop-layer pop-wd-mid st-one-msg on" id="popFinishMsgBox" v-if="popupErrorMsg">
      <div class="pop-cont-box ">
        <div class="message-box">{{ errorMsg }}</div>
        <div class="com-btn-box"><a href="javascript:;" class="" @click="onClickPopupErrorMsg(false)">확인</a></div>
      </div>
    </div>
    <!-- 신청 완료 메세지 -->

    <!-- 청약금지법 안내 -->
    <div class="dg-pop-layer pop-wd-mid pop-wh-full on" id="popAgreeInfo01Box" v-show="agree1">
      <div class="dg-pop-hd-box" style="margin-bottom:10px"><div class="pop-h1">청약금지법 안내</div> <a href="javascript:;" class="dg-pop-close" @click="popupAgree1(false)">닫기</a></div>
      <div class="pop-cont-box">
        제1장 총칙<br><br>

        제1조(목적) <br>
        이 법은 공직자 등에 대한 부정청탁 및 공직자 등의 금품
        등의 수수(收受)를 금지함으로써 공직자 등의 공정한
        직무수행을 보장하고 공공기관에 대한 국민의 신뢰를
        확보하는 것을 목적으로 한다.<br><br>

        제2조(정의) <br>
        이 법에서 사용하는 용어의 뜻은 다음과 같다.<br><br>

        1. "공공기관"이란 다음 각 목의 어느 하나에 해당하는 <br>
        기관ㆍ단체를 말한다.<br>
        가. 국회, 법원, 헌법재판소, 선거관리위원회, 감사원,
        국가인권위원회, 중앙행정기관(대통령 소속 기관과
        국무총리 소속 기관을 포함한다)과 그 소속 기관 및
        지방자치단체<br>
        나. 「공직자윤리법」 제3조의2에 따른 공직유관단체<br>
        다. 「공공기관의 운영에 관한 법률」 제4조에 따른 기관<br>
        라. 「초ㆍ중등교육법」, 「고등교육법」, 「유아교육법」 및
        그 밖의 다른 법령에 따라 설치된 각급 학교 및 「사립
        학교법」에 따른 학교법인

        제2조(정의) <br>
        이 법에서 사용하는 용어의 뜻은 다음과 같다.<br><br>

        1. "공공기관"이란 다음 각 목의 어느 하나에 해당하는 <br>
        기관ㆍ단체를 말한다.<br>
        가. 국회, 법원, 헌법재판소, 선거관리위원회, 감사원,
        국가인권위원회, 중앙행정기관(대통령 소속 기관과
        국무총리 소속 기관을 포함한다)과 그 소속 기관 및
        지방자치단체<br>
        나. 「공직자윤리법」 제3조의2에 따른 공직유관단체<br>
        다. 「공공기관의 운영에 관한 법률」 제4조에 따른 기관<br>
        라. 「초ㆍ중등교육법」, 「고등교육법」, 「유아교육법」 및
        그 밖의 다른 법령에 따라 설치된 각급 학교 및 「사립
        학교법」에 따른 학교법인

      </div>
      <div class="com-btn2-box" style="position: inherit;"><a href="javascript:;" @click="popupAgree1(false)">닫기</a></div>
    </div>
    <!-- 청약금지법 안내 -->


    <!-- 개인정보 수집 및 이용 -->
    <div class="dg-pop-layer on pop-wd-mid pop-wh-full" id="popAgreeInfo02Box" v-show="agree2">
      <div class="dg-pop-hd-box" style="margin-bottom:10px"><div class="pop-h1">개인정보 수집 및 이용</div> <a href="javascript:;" class="dg-pop-close" @click="popupAgree2(false)">닫기</a></div>
      <div class="pop-cont-box">
        제 1 조 (목적)<br>
        이 약관은 전자사랑모임(이하 "e-CLUB"라고 합니다)이
        제공하는 모든 서비스의 이용조건및 절차에 관한 사항과
        기타 필요한 사항을 규정함을 목적으로 합니다.<br><br>

        제 2 조 (약관의 효력)<br>
        1. 이 약관은 서비스를 통하여 이를 공지하거나 전자우편등
        기타의 방법으로 회원에게 통지함으로써 효력이 발생합니
        다.<br>
        2. e-CLUB은 필요시 이 약관의 내용을 변경할 수 있으며,
        변경된 약관은 제1항과 같은 방법으로 공지 또는 통지한
        때로부터 그 효력을 발생합니다.

      </div>
      <div class="com-btn2-box" style="position: inherit;"><a href="javascript:;" @click="popupAgree2(false)">닫기</a></div>
    </div>
    <!-- 개인정보 수집 및 이용 -->


  </div>
  <!-- 실제 본문 종료 -->

  <!-- id : contents : (E) -->
</template>

<script>
import PresentApi from "@/api/PresentApi";

export default {
  name: "Auth",
  components:{
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false,
    },
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popupErrorMsg=false
      }
    }
  },
  data(){
    return {
      presentId: this.$route.params.id,
      agree1 : false,
      agree2 : false,

      yyyyList:[],
      mmList:[],
      ddList:[],

      errorMsg: '',
      popupErrorMsg: false,

      userInfo:{
        name: '',
        birthYY: '',
        birthMM: '',
        birthDate:'',
        lunarBirth: false,
        moblNo: '',
        authNo: '',
        antiGraftLawTarget: 1,
        personalInformationAgreement: false
      },
      memberInfo:{},

      isSent: false,
      isAuthenticated: false
    }
  },
  computed:{
  },
  created(){
    for(let i=1930; i<2000; i++){
      this.yyyyList.push(i);
    }
    for(let i=1; i<=12; i++){
      let month = i;
      if(month<10){
        month = '0'+month
      }
      this.mmList.push(month);
    }
    for(let i=1; i<=31; i++){
      let day = i;
      if(day<10) {
        day = '0'+day
      }
      this.ddList.push(day);
    }
  },
  methods:{
    popupAgree1(e){
      this.agree1 = e
      this.$emit('click:bgDim', this.agree1)
    },
    popupAgree2(e){
      this.agree2 = e
      this.$emit('click:bgDim', this.agree2)
    },
    onClickSendAuthNo() {
      if (this.userInfo.name == "") {
        this.errorMsg = '이름을 입력해주세요.';
      } else if (this.userInfo.birthYY == "" || this.userInfo.birthMM == "" || this.userInfo.birthDate == "") {
        this.errorMsg = '생년월일을 입력해주세요.';
      } else if (this.userInfo.moblNo.length < 8) {
        this.errorMsg = '휴대폰 번호를 확인해주세요.';
      } else if (!this.userInfo.personalInformationAgreement) {
        this.errorMsg = '개인정보 수집 이용에 대한 동의를 해주세요.';
      } else if (this.userInfo.antiGraftLawTarget == 0) {
        this.errorMsg = '※본인 혹은 배우자가 청탁금지 대상자에 해당 될 경우 ' +
            '전자사랑모임 사무실 측으로 ' +
            '연락 주시면 보다 자세한 설명 드리도록 하겠습니다. ' +
            '(TEL.02-558-3401)';
      } else {
        let param = {
          birthDay: this.userInfo.birthYY + "-" + this.userInfo.birthMM + "-" + this.userInfo.birthDate,
          isAntiGraftLawTarget: this.userInfo.antiGraftLawTarget == 0 ? true : false,
          mobileNo: this.userInfo.moblNo,
          name: this.userInfo.name,
          presentId: this.presentId
        }

        PresentApi.sendMobileAuthNo(param).then((data) => {
          this.errorMsg = "인증번호를 발송했습니다.";
          this.isSent = true;
          this.memberInfo = data;
        }).catch(error => {
          if (error.response.status == 400) {
            switch (error.response.data.message) {
              case "NOT_FOUND_USER" :
                this.errorMsg = "일치하는 회원이 없습니다. " +
                    "\n전자사랑모임 사무실 측으로 문의주세요 (TEL.02-558-3401)";
                break;
              case "ANTI_GRAFT_LAW_USER" :
                this.errorMsg = "청탁금지법 대상 회원이 아닙니다.";
                break;
              case "NOT_EXIST" :
                this.errorMsg = "선물 회차 정보가 없습니다.";
                break;
              case "CLOSED" :
                this.errorMsg = "종료된 선물 회차입니다.";
                break;
              case "ABNORMAL_USER" :
                this.errorMsg = "비대상 회원입니다." +
                    "\n전자사랑모임 사무실 측으로 문의주세요 (TEL.02-558-3401)";
                break;
              case "DUPLICATE_REQUEST" :
                this.errorMsg = "이미 신청한 회원입니다." +
                    "\n전자사랑모임 사무실 측으로 문의주세요 (TEL.02-558-3401)";
                break;
            }
          } else {
            this.errorMsg = "오류가 발생하였습니다. 다시 시도해주세요.";
          }
        })
      }

      this.onClickPopupErrorMsg(true);
    },
    onInputMobile(e){
      this.isSent=false;
      this.isAuthenticated=false;
      this.userInfo.moblNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    },
    onClickCheckAuth(){
      if(!this.isSent){
        this.errorMsg = '인증번호를 발송해주세요.';
        return this.onClickPopupErrorMsg(true);
      }

      PresentApi.checkMobileAuthNo(this.userInfo.authNo, this.userInfo.moblNo).then((data)=>{
        if(data) {
          this.errorMsg = '인증되었습니다.';
          this.isAuthenticated = true;
        }else{
          this.errorMsg = '인증번호를 확인해주세요.';
        }
      }).catch(error=> {
        this.errorMsg = error.response.data.message;
      });

      return this.onClickPopupErrorMsg(true);
    },
    onClickNext(){
      if(this.isAuthenticated) {
        let param = { ...this.memberInfo, ...{
            authNo:this.userInfo.authNo
          }
        }

        this.$router.push({name:'register', params:{memberInfo:param}});
      }else{
        this.errorMsg = '휴대폰 인증을 해주세요.';
        this.onClickPopupErrorMsg(true);
      }
    },
    onClickPopupErrorMsg(e){
      this.popupErrorMsg = e;
      this.$emit('click:bgDim', e);
      if(!e) {
        this.errorMsg = "";
      }
    }
  },
}
 
</script>

<style scoped>

</style>